import {ADD_COMMENT, ADD_COMMENTS, SET_AUTOSCROLL} from "../actions/ActionTypes";

const initialState = {
    autoScrolls: true,
    isLoading: true,
    comments: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case ADD_COMMENT:
            return {
                ...state,
                comments: [...state.comments, {comment: action.payload.comment, avatar: action.payload.avatar, name: action.payload.name}]
            };
        case ADD_COMMENTS:
            return {
                ...state,
                comments: action.payload,
                isLoading: false,
            };
        case SET_AUTOSCROLL:
            return {
                ...state,
                autoScrolls: action.payload.autoScrolls,
            };
        default:
            return state;
    }
}
