import React from 'react';
import {Layout, Row, Col, Typography, Space} from 'antd';

const { Footer } = Layout;
const { Link, Text } = Typography;


function AppFooter() {
    return (
        <Footer>
            <Row gutter={[0,40]} justify="space-between" className='inner-container'>
                <Col xs={24} sm={24} md={24} lg={9} xl={9} xxl={9}>
                    <Space direction='vertical' size='middle'>
                        <div>
                            <img loading="lazy" src={'https://d22z6rj5fefjac.cloudfront.net/assets/Logos/Horizontal_Logo_White.png'} width={200} alt={''}/>
                        </div>
                        <Text>
                            ©2020 by Rokfin. All Rights Reserved.
                        </Text>
                    </Space>
                </Col>
                <Col xs={24} sm={10} md={8} lg={6} xl={7} xxl={7}>
                    <Space direction='vertical'>
                        <Text strong>
                            ROKFIN
                        </Text>
                        <Link strong href="https://rokfin.com/about" rel="noopener noreferrer" target="_blank" >
                            About
                        </Link>
                        <Link strong href="https://medium.com/@Rokfin/rokfin-user-faq-9d8d11f1bbdc" rel="noopener noreferrer" target="_blank" >
                            FAQ
                        </Link>
                        <Link strong href="mailto:support@rokfin.com" >
                            Contact
                        </Link>
                        <Link strong href="https://rokfin.com/privacy" rel="noopener noreferrer" target="_blank" >
                            Privacy Policy
                        </Link>
                        <Link strong href="https://rokfin.com/tou" rel="noopener noreferrer" target="_blank" >
                            Terms of Service
                        </Link>
                        <Link strong href="https://rokfin.com/copyright" rel="noopener noreferrer" target="_blank" >
                            Copyright & DMCA Policy
                        </Link>
                    </Space>
                </Col>
                <Col xs={24} sm={9} md={8} lg={6} xl={6} xxl={6}>
                    <Space direction='vertical'>
                        <Text strong>
                            RAE NETWORK
                        </Text>
                        <Link strong href="https://rokfin.com/rae/about" rel="noopener noreferrer" target="_blank" >
                            About
                        </Link>
                        <Link strong href="https://medium.com/@RAEToken/how-to-buy-and-hold-rae-tokens-via-kyberswap-and-the-coinbase-wallet-app-u-s-customers-8f55e3558f89" rel="noopener noreferrer" target="_blank" >
                            Buy Tokens
                        </Link>
                        <Link strong href="https://rokfin.com/rae/dashboard" rel="noopener noreferrer" target="_blank" >
                            Dashboard
                        </Link>
                        <Link strong href="https://medium.com/@RAEToken" rel="noopener noreferrer" target="_blank" >
                            Medium
                        </Link>
                    </Space>
                </Col>
                <Col xs={24} sm={5} md={8} lg={3} xl={2} xxl={2}>
                    <Space direction='vertical'>
                        <Text strong>
                            FOLLOW US
                        </Text>
                        <Link strong href="https://twitter.com/TheRokfin" rel="noopener noreferrer" target="_blank" >
                            Twitter
                        </Link>
                        <Link strong href="https://www.facebook.com/TheRokfin/" rel="noopener noreferrer" target="_blank" >
                            Facebook
                        </Link>
                        <Link strong href="https://medium.com/@Rokfin" rel="noopener noreferrer" target="_blank" >
                            Medium
                        </Link>
                        <Link strong href="https://www.instagram.com/rokfin/" rel="noopener noreferrer" target="_blank" >
                            Instagram
                        </Link>
                    </Space>
                </Col>
            </Row>
        </Footer>
    );
}

export default AppFooter;
