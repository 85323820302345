import React from 'react';
import {Button, Col, Row, Space} from "antd";
import {COUNTDOWN_BACKGROUND_IMAGE} from "../../util/Constants";

const countdownBackgroundStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: `url(${COUNTDOWN_BACKGROUND_IMAGE})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
}


function VideoPlayerStopped() {
    return (
        <div className='countdown'>
            <div style={{...countdownBackgroundStyle}}>
                <Row justify='center' align='middle' style={{height: '100%'}}>
                    <Col className='countdown-overlay'>
                        <Space direction='vertical'>
                            <div className='ant-statistic-title'>
                                The live stream has ended.
                            </div>
                            <Button onClick={() => window.location.reload(true)}>
                                Refresh Page
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default VideoPlayerStopped;
