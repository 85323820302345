import {
    SET_DATA,
    SET_USER,
    SET_NAME,
    ADD_COMMENT,
    ADD_COMMENTS,
    SET_AUTOSCROLL,
    SET_URLS,
    STREAM_COMPLETE, SET_VIDEOS, SET_PPV_DATA
} from "./ActionTypes";
import {formRoles} from "../../util/Helpers";

export const setPpvData = (data) => dispatch => {
    dispatch({
        type: SET_PPV_DATA,
        payload: {...data}
    });
};

export const setVideos = ({data, error}) => dispatch => {
    dispatch({
        type: SET_VIDEOS,
        payload: {
            showCountdown: data.find(video => video.url === null || video.url === ''),
            error: error,
            data: data
        }
    });
};

export const setData = (data) => dispatch => {
    dispatch({
        type: SET_DATA,
        payload: {...data}
    });
};

export const setUrls = (url, backupUrl, fallbackUrl) => dispatch => {
    dispatch({
        type: SET_URLS,
        payload: {
            url: url,
            backupUrl: backupUrl,
            fallbackUrl: fallbackUrl,
        }
    });
};

export const setUser = (tokenParsed, token) => dispatch => {
    dispatch({
        type: SET_USER,
        payload: {
            token: token,
            id: tokenParsed.sub.substring(tokenParsed.sub.lastIndexOf(":") + 1),
            email: tokenParsed.email,
            username: tokenParsed.preferred_username,
            roles: formRoles(tokenParsed.realm_access.roles)
        }
    });
};

export const setStreamComplete = () => dispatch => {
    dispatch({
        type: STREAM_COMPLETE,
        payload: {
            streamComplete: true
        }
    });
};

export const setDisplayName = (name) => dispatch => {
    dispatch({
        type: SET_NAME,
        payload: {name}
    });
};

export const addComment = (comment, name, avatar) => dispatch => {
    dispatch({
        type: ADD_COMMENT,
        payload: {comment, name, avatar}
    });
};

export const addComments = (comments) => dispatch => {
    dispatch({
        type: ADD_COMMENTS,
        payload: comments
    });
};

export const setAutoScroll = (autoScrolls) => dispatch => {
    dispatch({
        type: SET_AUTOSCROLL,
        payload: {autoScrolls}
    });
};
