import Keycloak from 'keycloak-js';
import {REACT_APP_KEYCLOAK_CLIENT_ID, REACT_APP_KEYCLOAK_REALM, REACT_APP_KEYCLOAK_URL} from "../util/Constants";

const keycloak = new Keycloak({
    realm: REACT_APP_KEYCLOAK_REALM,
    url: REACT_APP_KEYCLOAK_URL,
    clientId: REACT_APP_KEYCLOAK_CLIENT_ID,
    "ssl-required": "none",
    "public-client": true,
    "bearer-only": false,
    "verify-token-audience": true,
    "use-resource-role-mappings": true,
    "confidential-port": 0,
});


export default keycloak;
