import React from 'react';
import {Card, List, Avatar, Space, Typography} from "antd";
import {CREATORS_LIST_ITEMS, CREATORS_LIST_TITLE} from "../util/Constants";

const {Title} = Typography;

function CreatorsList() {
    return (
        <Space direction='vertical' size='large'>
            <Title level={3} style={{marginLeft: 20, marginBottom: 0}}>
                {CREATORS_LIST_TITLE}
            </Title>
            <List
                grid={{
                    xs: 1,
                    sm: 2,
                    md: 3,
                    lg: 3,
                    xl: 5,
                    xxl: 5,
                }}
                dataSource={CREATORS_LIST_ITEMS}
                renderItem={item => (
                    <List.Item>
                        <div className='card-container'>
                            <Card style={{marginTop: 10}} bordered={false}>
                                <Card.Meta
                                    avatar={<Avatar loading="lazy" size='large' src={item.url} />}
                                    title={item.title}
                                    description={item.description}
                                />
                            </Card>
                        </div>
                    </List.Item>
                )}
            />
        </Space>
    );
}

export default CreatorsList;
