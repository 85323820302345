import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {REACT_APP_ENVIRONMENT} from "../../util/Constants";

import RootReducer from '../reducers';

export default function configureStore(preloadedState) {
    const middleware = [thunkMiddleware];
    const middlewareEnhancer = applyMiddleware(...middleware);

    if (REACT_APP_ENVIRONMENT !== 'development') {
        return createStore(RootReducer, preloadedState, compose(middlewareEnhancer));
    } else {
        const enhancers = [middlewareEnhancer];
        const composedEnhancers = composeWithDevTools(...enhancers);
        return createStore(RootReducer, preloadedState, composedEnhancers);
    }
}

