import React, {useEffect, useState} from 'react';
import {Col, Row, Statistic, Button, Space} from 'antd';
import {connect} from "react-redux";
import {isLessThan2DaysAway, countdownComplete} from "../../util/Helpers";
import {
    COUNTDOWN_BACKGROUND_IMAGE,
    OVERLAY_COLOR_LEFT,
    OVERLAY_COLOR_MIDDLE,
    OVERLAY_COLOR_RIGHT,
} from "../../util/Constants";

const { Countdown } = Statistic;

const countdownBackgroundStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: `url(${COUNTDOWN_BACKGROUND_IMAGE})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
}


function EventCountdown(props) {
    const [isCountdownComplete, setCountdownComplete] = useState(false);

    useEffect(() => {
        setCountdownComplete(countdownComplete(props.data.startTime));
    }, [props.data.startTime]);

    return (
        <div className='countdown'>
            <div style={{...countdownBackgroundStyle}}>
                <Row justify='center' align='middle' style={{height: '100%'}}>
                    <Col
                        className='countdown-overlay'
                        style={{backgroundImage: `linear-gradient(to right, ${OVERLAY_COLOR_LEFT}, ${OVERLAY_COLOR_MIDDLE}, ${OVERLAY_COLOR_RIGHT}`}}
                    >
                        { isCountdownComplete ?
                            <Space direction='vertical'>
                                <div className='ant-statistic-title'>
                                    Event starting soon...
                                </div>
                                <Button onClick={() => window.location.reload(true)}>
                                    Refresh Page
                                </Button>
                            </Space>
                            :
                            <Countdown
                                title='Event will start in'
                                value={props.data.startTime}
                                onFinish={() => setCountdownComplete(true)}
                                format={isLessThan2DaysAway(props.data.startTime) ? `H [hr ·] mm [min ·] ss [sec]` : `D [days ·] HH [hr ·] mm [min]`}
                            />
                        }
                    </Col>
                </Row>
            </div>
        </div>
    )
}


const mapStateToProps = state => ({
    data: state.PpvReducer
});

export default connect(mapStateToProps, null)(EventCountdown);
