// Environment Variables
export const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
export const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
export const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL
export const REACT_APP_CORE_BASE_API_URL = process.env.REACT_APP_CORE_BASE_API_URL
export const REACT_APP_DONATE_API_BASE_URL = process.env.REACT_APP_DONATE_API_BASE_URL
export const REACT_APP_PAYMENT_API_BASE_URL = process.env.REACT_APP_PAYMENT_API_BASE_URL
export const REACT_APP_ABLY_KEY = process.env.REACT_APP_ABLY_KEY
export const REACT_APP_ATATUS_API_KEY = process.env.REACT_APP_ATATUS_API_KEY
export const REACT_APP_CHARGIFY_PUBLIC_KEY = process.env.REACT_APP_CHARGIFY_PUBLIC_KEY
export const REACT_APP_CHARGIFY_SERVER_HOST = process.env.REACT_APP_CHARGIFY_SERVER_HOST
export const REACT_APP_KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM
export const REACT_APP_KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL
export const REACT_APP_KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID
export const REACT_APP_PLAYER_LICENSE_KEY = process.env.REACT_APP_PLAYER_LICENSE_KEY
export const REACT_APP_PLAYER_ID = process.env.REACT_APP_PLAYER_ID
export const REACT_APP_PLAYER_SCRIPT = process.env.REACT_APP_PLAYER_SCRIPT
export const REACT_APP_SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT
export const REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const REACT_APP_GTM_ID = process.env.REACT_APP_GTM_ID
export const REACT_APP_GTM_ENVIRONMENT_AUTH = process.env.REACT_APP_GTM_ENVIRONMENT_AUTH
export const REACT_APP_GTM_PREVIEW = process.env.REACT_APP_GTM_PREVIEW
export const REACT_APP_META_FB_APP_ID = process.env.REACT_APP_META_FB_APP_ID
export const REACT_APP_ABLY_CHANNEL = "persisted:comments-stream-live-app"

// Analytics
export const PPV_PAGE_VIEW = "PPV_PAGE_VIEW"
export const POST_PAGE_VIEW = "POST_PAGE_VIEW"
export const SIGNUP_PAGE_VIEW = "SIGNUP_PAGE_VIEW"
export const PAYMENT_PAGE_REACHED = "PAYMENT_PAGE_REACHED"
export const PAYMENT_FORM_SUBMITTED = "PAYMENT_FORM_SUBMITTED"
export const CARD_VERIFIED = "CARD_VERIFIED"
export const CREATING_SUBSCRIPTION_FAILED = "CREATING_SUBSCRIPTION_FAILED"
export const CARD_VERIFICATION_FAILED = "CARD_VERIFICATION_FAILED"
export const CONTENT_REACHED_30_SECONDS = "CONTENT_REACHED_30_SECONDS"
export const BUFFERING_STARTED = "BUFFERING_STARTED"
export const VIDEO_PLAYER_ERROR = "VIDEO_PLAYER_ERROR"
export const VIDEO_PLAYER_WARNING = "VIDEO_PLAYER_WARNING"

// Comments
export const COMMENT_TEXT_MAX_LENGTH = 300
export const COLORS = ["#08AD9F", "#14DCAF", "#252836", "#383F51", "#C7CBDB"]

// Helper Functions
export const ROLES_CAN_VIEW = ["subscriber_10", "creator", "admin"]
export const ROLES_SUBSCRIBER = ["subscriber_10"]
export const MONTH_NAMES = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sept",
	"Oct",
	"Nov",
	"Dec",
]
export const DAY_NAMES = [
	"Sunday",
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday",
]

// Video Player
export const LIVE_STREAM_ENDED_ERROR = 230001
export const VIDEO_PLAYER_ERROR_MESSAGES = {
	intl: {
		en: {
			errors: {
				liveStreamDown: "This live stream has ended.",
				cantPlayVideo: "This live stream cannot be played. Please refresh the page.",
				cantLoadPlayer: "The live stream failed to load. Please refresh the page.",
				technicalError: "This live stream cannot be played. Please refresh the page.",
			},
		},
	},
}
export const VIDEO_PLAYER_CONFIG = {
	youtube: {
		playerVars: {
			autoplay: 1,
			controls: 1,
			modestbranding: 1,
		},
	},
}

// Static Data
export const CDN_PREFIX = "https://d22z6rj5fefjac.cloudfront.net/"

export const OVERLAY_COLOR_LEFT = "rgba(255,255,255,0.1)"
export const OVERLAY_COLOR_MIDDLE = "rgba(255,255,255,0.1)"
export const OVERLAY_COLOR_RIGHT = "rgba(255,255,255,0.1)"

export const COUNTDOWN_BACKGROUND_IMAGE = `https://s3.us-west-2.amazonaws.com/rkfn-production-media/%2Fuser/419118/post/a5885299-5035-4af9-87f2-0e12823648af/thumbnail/c091fe34-32ee-4b05-8adc-59ed05003b2a`
export const BANNER_BACKGROUND_IMAGE = `https://s3.us-west-2.amazonaws.com/rkfn-production-media/%2Fuser/419118/post/a5885299-5035-4af9-87f2-0e12823648af/thumbnail/c091fe34-32ee-4b05-8adc-59ed05003b2a`

export const FEATURED_LIST_TITLE = "Featured Wrestlers"

export const FEATURED_LIST_ITEMS = [
	{
		title: "",
		url: `${CDN_PREFIX}assets/CollegiateDuals/Spencer+Lee.jpg`,
	},
	{
		title: "",
		url: `${CDN_PREFIX}assets/CollegiateDuals/Roman+Bravo-Young.jpg`,
	},
	{
		title: "",
		url: `${CDN_PREFIX}assets/CollegiateDuals/Austin+DeSanto.jpg`,
	},
	{
		title: "",
		url: `${CDN_PREFIX}assets/CollegiateDuals/Nick+Lee.jpg`,
	},
	{
		title: "",
		url: `${CDN_PREFIX}assets/CollegiateDuals/Jaydin+Eierman.jpg`,
	},
	{
		title: "",
		url: `${CDN_PREFIX}assets/CollegiateDuals/Yianni+DIAKOMIHALIS.jpg`,
	},
	{
		title: "",
		url: `${CDN_PREFIX}assets/CollegiateDuals/ANTHONY VALENCIA.jpg`,
	},
	{
		title: "",
		url: `${CDN_PREFIX}assets/CollegiateDuals/Mehki+Lewis.jpg`,
	},
	{
		title: "",
		url: `${CDN_PREFIX}assets/CollegiateDuals/Aaron+Brooks.jpg`,
	},
	{
		title: "",
		url: `${CDN_PREFIX}assets/CollegiateDuals/COHLTON+SCHULTZ.jpg`,
	},
]

export const CREATORS_LIST_TITLE = "Included With Your Subscription"

export const CREATORS_LIST_ITEMS = [
	{
		title: "Illinois Matmen",
		description:
			"Illinois’ Premier Wrestling Website covering all levels of wrestling from kids to high school to college to international.",
		url: "https://img.production.rokfin.com/eyJidWNrZXQiOiJya2ZuLXByb2R1Y3Rpb24tbWVkaWEiLCJrZXkiOiIvdXNlci8xMjg5NDMvcHJvZmlsZS9hdmF0YXIvMGU4NzYzNjUtOTZmYi00ZTczLTkxYzItODdiYTZmZTk1NTU3IiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjozMDAsImhlaWdodCI6MzAwLCJmaXQiOiJjb3ZlciJ9fX0=",
	},
	{
		title: "Unlimited Hangout",
		description: "Uncensored Investigative Journalism with Whitney Webb.",
		url: "https://img.production.rokfin.com/eyJidWNrZXQiOiJya2ZuLXByb2R1Y3Rpb24tbWVkaWEiLCJrZXkiOiIvdXNlci83MjU4Mi9wcm9maWxlL2F2YXRhci83MDFhY2IxMi01YjVmLTRmYzEtOTE2YS00ZDFjMDc4OThiMmUiLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjMwMCwiaGVpZ2h0IjozMDAsImZpdCI6ImNvdmVyIn19fQ==",
	},
	{
		title: "Tom Lemming",
		description:
			"Co-Host of The Lemming Report on CBS Sports. 41 years & counting in college football recruiting.",
		url: "https://img.production.rokfin.com/eyJidWNrZXQiOiJya2ZuLXByb2R1Y3Rpb24tbWVkaWEiLCJrZXkiOiIvdXNlci81NTgyMy9wcm9maWxlL2F2YXRhci8xMDllNTgyMy1lZTcwLTRlZmItOGRiZi1kYmFjN2M3NTdkM2YiLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjMwMCwiaGVpZ2h0IjozMDAsImZpdCI6ImNvdmVyIn19fQ==",
	},
	{
		title: "IAwrestle",
		description: "Iowa's Premier Site for Wrestling Rankings, Interviews, Podcasts and MORE!",
		url: "https://img.production.rokfin.com/eyJidWNrZXQiOiJya2ZuLXByb2R1Y3Rpb24tbWVkaWEiLCJrZXkiOiIvdXNlci80MDExOC9wcm9maWxlL2F2YXRhci9kZWMzZDQ4NS00ZTk4LTQ4MTQtOGNlNy03YjE5ODc3MjA3MzUiLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjMwMCwiaGVpZ2h0IjozMDAsImZpdCI6ImNvdmVyIn19fQ==",
	},
	{
		title: "College Football Today",
		description:
			"College Football Today conducts global football recruiting rankings. Owned by Scout Trout Elite LLC.",
		url: "https://img.production.rokfin.com/eyJidWNrZXQiOiJya2ZuLXByb2R1Y3Rpb24tbWVkaWEiLCJrZXkiOiIvdXNlci82NjM0My9wcm9maWxlL2F2YXRhci9kMmFlN2U3NS1jNDkzLTQzMzUtYjk1Zi0yOGNjNmI2ZGQzODMiLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjMwMCwiaGVpZ2h0IjozMDAsImZpdCI6ImNvdmVyIn19fQ==",
	},
	{
		title: "Wisconsin RTC",
		description: "Wisconsin’s Premier Wrestling Website high school, college, and international.",
		url: "https://img.production.rokfin.com/eyJidWNrZXQiOiJya2ZuLXByb2R1Y3Rpb24tbWVkaWEiLCJrZXkiOiIvdXNlci8xMDUyMzgvcHJvZmlsZS9hdmF0YXIvOWYxOGZkYzgtZTY2Ni00MGViLTg2NmMtNTk1ZDY4N2RmODZlIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjozMDAsImhlaWdodCI6MzAwLCJmaXQiOiJjb3ZlciJ9fX0=",
	},
	{
		title: "Jimmy Dore",
		description:
			"“Jimmy Dore is outrageous and outraged, bothersome and bothered. A crucial, profane, passionate voice for progressives and freethinkers in 21st century America.” -Patton Oswalt",
		url: "https://img.production.rokfin.com/eyJidWNrZXQiOiJya2ZuLXByb2R1Y3Rpb24tbWVkaWEiLCJrZXkiOiIvdXNlci82NTQyOS9wcm9maWxlL2F2YXRhci85MWY0ZDk5NS0zNDVmLTQ0NTEtYjE5Ny02NTZlNDYyZDBhZDYiLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjMwMCwiaGVpZ2h0IjozMDAsImZpdCI6ImNvdmVyIn19fQ==",
	},
	{
		title: "Nittany Lion Wrestling Club",
		description: "Home for all NLWC content. Featuring live events exclusively on Rokfin.",
		url: "https://img.production.rokfin.com/eyJidWNrZXQiOiJya2ZuLXByb2R1Y3Rpb24tbWVkaWEiLCJrZXkiOiIvdXNlci83MTIwNS9wcm9maWxlL2F2YXRhci9hMWFjNWZiZi05NGZkLTQ4YWUtYjY1Ny1hMjY2MjRiN2Y1NDEiLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjMwMCwiaGVpZ2h0IjozMDAsImZpdCI6ImNvdmVyIn19fQ==",
	},
	{
		title: "EasternPAFootball",
		description:
			"EasternPAFootball.com is the NUMBER ONE source for high school football in the Commonwealth of Pennsylvania.",
		url: "https://img.production.rokfin.com/eyJidWNrZXQiOiJya2ZuLXByb2R1Y3Rpb24tbWVkaWEiLCJrZXkiOiIvdXNlci83MzM1MS9wcm9maWxlL2F2YXRhci8xZjM3NmJmNy1kYzkxLTRlZGEtYTg0Ny1jMzkyZTBkM2Q2YjkiLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjMwMCwiaGVpZ2h0IjozMDAsImZpdCI6ImNvdmVyIn19fQ==",
	},
	{
		title: "Willie Saylor - MatScouts",
		description:
			"Covering all things wrestling including high school prospects and college recruiting.",
		url: "https://img.production.rokfin.com/eyJidWNrZXQiOiJya2ZuLXByb2R1Y3Rpb24tbWVkaWEiLCJrZXkiOiIvdXNlci8yMDgxOS9wcm9maWxlL2F2YXRhci9hNjZmZjE0OS0zOTQ4LTRiMjctYjcyYy1lM2Y3ODJiMzFkZWMiLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjMwMCwiaGVpZ2h0IjozMDAsImZpdCI6ImNvdmVyIn19fQ==",
	},
]
