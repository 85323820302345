import React from 'react';
import { Result, Button } from 'antd';
import {withRouter} from 'react-router-dom';

function NotFound(props) {
    return (
        <Result
            status="500"
            title="Hmm...something's not right."
            subTitle="We'll help you make it back real quick."
            extra={<Button danger size='large' onClick={() => props.history.push('/')} type="primary">Back Home</Button>}
        />
    );
}

export default withRouter(NotFound);
