import {COLORS, DAY_NAMES, MONTH_NAMES, ROLES_CAN_VIEW, ROLES_SUBSCRIBER} from "./Constants";

export function formatDate(dateString) {
    if (!dateString) {
        return "Saturday, Sept 19 at 7:30 pm ET";
    }

    const date = new Date(dateString);

    return ` ${DAY_NAMES[date.getDay()]}, ${MONTH_NAMES[date.getMonth()]} ${date.getDate()} at ${hoursHelper(date.getHours())}:${minutesHelper(date.getMinutes())} ${ampmHelper(date.getHours())}`
}

function ampmHelper(hours) {

    if (hours > 12) {
        return 'pm';
    } else {
        return 'am';
    }

}

function minutesHelper(minutes) {
    if (minutes < 10) {
        return '0' + minutes;
    } else {
        return minutes;
    }
}

function hoursHelper(hours) {
    if (hours > 12) {
        return hours - 12;
    } else {
        return hours;
    }
}

export function parseToken(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function canView(token) {
    if (!token) {
        return false;
    }

    let parsedToken = parseToken(token);
    let roles = parsedToken?.realm_access?.roles

    if (!roles) {
        return false;
    }

    return ROLES_CAN_VIEW.some(r=> roles.includes(r));
}

export function isSubscriber(token) {
    if (!token) {
        return false;
    }

    let parsedToken = parseToken(token);
    let roles = parsedToken?.realm_access?.roles

    if (!roles) {
        return false;
    }

    return ROLES_SUBSCRIBER.some(r=> roles.includes(r));
}

export function formRoles(roles) {
    if (!roles) {
        return []
    }

    let output = [];

    for (let i = 0; i < roles.length; i++) {
        switch (roles[i]) {
            case 'user':
                output.push({id: 1, name: 'ROLE_USER'});
                break;
            case 'admin':
                output.push({id: 2, name: 'ROLE_ADMIN'});
                break;
            case 'creator':
                output.push({id: 3, name: 'ROLE_CREATOR'})
                break;
            case 'subscriber_10':
                output.push({id: 4, name: 'ROLE_SUBSCRIBER_10'});
                break;
            case 'contributor':
                output.push({id: 5, name: 'ROLE_CONTRIBUTOR'});
                break;
            default:
                break;
        }
    }

    return output;
}

export function getAvatarColor(name) {
    if (!name) {
        return;
    }

    name = name.substr(0, 6);

    let hash = 0;
    for (let i = 0; i < name.length; i++) {
        hash = 31 * hash + name.charCodeAt(i);
    }
    let index = Math.abs(hash % COLORS.length);
    return COLORS[index];
}

export function isLessThan2DaysAway(date) {
    if (!date) {
        return false;
    }

    const DAYS = 1000 * 60 * 60 * 24 * 2;
    const daysAway = Date.now() + DAYS;

    return date < daysAway;
}

export function countdownComplete(date) {
    if (!date) {
        return false;
    }

    return date < Date.now();
}

export function hoursAgo(date) {
    if (!date) {
        return false;
    }

    let millisecondsDifference = Math.abs(Date.now() - date);

    return Math.floor(millisecondsDifference / 36e5);
}

export function getUrlParams(locationParams, parameterName) {
    if (!locationParams) {
        return null;
    }

    let result = null, tmp = [];
    locationParams
        .substr(1)
        .split("&")
        .forEach(function (item) {
            tmp = item.split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}
