import {
    PPV_PAGE_VIEW,
    POST_PAGE_VIEW,
    SIGNUP_PAGE_VIEW,
    PAYMENT_PAGE_REACHED,
    PAYMENT_FORM_SUBMITTED,
    CARD_VERIFIED,
    CARD_VERIFICATION_FAILED,
    CREATING_SUBSCRIPTION_FAILED,
    BUFFERING_STARTED,
    VIDEO_PLAYER_ERROR,
    VIDEO_PLAYER_WARNING,
    CONTENT_REACHED_30_SECONDS
} from '../util/Constants';
import {analyticsTrackEvent, authenticatedTrackEvent} from './API';

export function triggerPostPageView(creatorId, postId, contentType, mediaId, affiliateId, premiumPlan, postTitle, token) {
    let data = {
        creator_id: creatorId,
        post_id: postId,
        content_type: contentType,
        media_id: mediaId,
        affiliate_id: affiliateId,
        premium_plan: premiumPlan,
        post_title: postTitle,
    }

    analyticsTrackEvent(POST_PAGE_VIEW, data, token)
        .then(response => {
            if (!response.success) {
                window.analytics.track(POST_PAGE_VIEW, data);
            }
        })
        .catch(error => {
            window.analytics.track(POST_PAGE_VIEW, data);
        });
}

export function triggerPpvPageView(id, title, productId, canViewEvent, price) {
    let data = {
        id: id,
        title: title,
        productId: productId,
        canViewEvent: canViewEvent,
        price: price,
    }

    analyticsTrackEvent(PPV_PAGE_VIEW, data)
        .then(response => {
            if (!response.success) {
                window.analytics.track(PPV_PAGE_VIEW, data);
            }
        })
        .catch(error => {
            window.analytics.track(PPV_PAGE_VIEW, data);
        });
}

export function triggerSignUpPageView(token) {
    analyticsTrackEvent(SIGNUP_PAGE_VIEW, {}, token)
        .then(response => {
            if (!response.success) {
                window.analytics.track(SIGNUP_PAGE_VIEW, {});
            }
        })
        .catch(error => {
            window.analytics.track(SIGNUP_PAGE_VIEW, {});
        });
}

export function triggerPaymentPageReached(creator_id, creator_name, token) {
    let data = {
        point_of_entry: 'livestream',
        creator_id: creator_id,
        creator_name: creator_name
    };

    authenticatedTrackEvent(PAYMENT_PAGE_REACHED, data, token)
        .then(response => {
            if (!response.success) {
                window.analytics.track(PAYMENT_PAGE_REACHED, data);
            }
        })
        .catch(error => {
            window.analytics.track(PAYMENT_PAGE_REACHED, data);
        });

}

export function triggerPaymentFormSubmitted(token) {
    authenticatedTrackEvent(PAYMENT_FORM_SUBMITTED, {}, token)
        .then(response => {
            if (!response.success) {
                window.analytics.track(PAYMENT_FORM_SUBMITTED, {});
            }
        })
        .catch(error => {
            window.analytics.track(PAYMENT_FORM_SUBMITTED, {});
        });
}

export function triggerCardVerified(token) {
    authenticatedTrackEvent(CARD_VERIFIED, {}, token)
        .then(response => {
            if (!response.success) {
                window.analytics.track(CARD_VERIFIED, {});
            }
        })
        .catch(error => {
            window.analytics.track(CARD_VERIFIED, {});
        });
}

export function triggerCardVerificationFailed(token) {

    authenticatedTrackEvent(CARD_VERIFICATION_FAILED, {}, token)
        .then(response => {
            if (!response.success) {
                window.analytics.track(CARD_VERIFICATION_FAILED, {});
            }
        })
        .catch(error => {
            window.analytics.track(CARD_VERIFICATION_FAILED, {});
        });
}

export function triggerCreatingSubscriptionFailed(token) {

    authenticatedTrackEvent(CREATING_SUBSCRIPTION_FAILED, {}, token)
        .then(response => {
            if (!response.success) {
                window.analytics.track(CREATING_SUBSCRIPTION_FAILED, {});
            }
        })
        .catch(error => {
            window.analytics.track(CREATING_SUBSCRIPTION_FAILED, {});
        });
}

export function triggerContentReached30Seconds(data, token) {
    authenticatedTrackEvent(CONTENT_REACHED_30_SECONDS, data, token)
        .then(response => {
            if (!response.success) {
                window.analytics.track(CONTENT_REACHED_30_SECONDS, data);
            }
        })
        .catch(error => {
            window.analytics.track(CONTENT_REACHED_30_SECONDS, data);
        });
}

export function triggerBufferingStarted(info, creatorId, mediaId, postId, contentType, token) {
    const wasPlaying = info.oldstate === "playing";
    const nowBuffering = info.newstate === "buffering";
    const stalled = info.reason === "stalled";

    if (wasPlaying && nowBuffering && stalled) {

        let data = {
            CREATOR_ID: creatorId,
            MEDIA_ID: mediaId,
            POST_ID: postId,
            CONTENT_TYPE: contentType
        };

        authenticatedTrackEvent(BUFFERING_STARTED, data, token)
            .then(response => {
                if (!response.success) {
                    window.analytics.track(BUFFERING_STARTED, data);
                }
            })
            .catch(error => {
                window.analytics.track(BUFFERING_STARTED, data);
            });
    }
}

export function triggerVideoPlaybackError(event, token) {
    let data = {
        code: event?.code,
        message: event?.message,
        details: event?.sourceError?.details,
        type: event?.sourceError?.type,
        url: event?.sourceError?.url
    };


    authenticatedTrackEvent(VIDEO_PLAYER_ERROR, data, token)
        .then(response => {
            if (!response.success) {
                window.analytics.track(BUFFERING_STARTED, data);
            }
        })
        .catch(error => {
            window.analytics.track(BUFFERING_STARTED, data);
        });
}

export function triggerVideoPlaybackWarning(event, token) {
    let data = {
        code: event?.code,
        details: event?.sourceError?.details,
        type: event?.sourceError?.type,
        url: event?.sourceError?.url
    };

    authenticatedTrackEvent(VIDEO_PLAYER_WARNING, data, token)
        .then(response => {
            if (!response.success) {
                window.analytics.track(VIDEO_PLAYER_WARNING, data);
            }
        })
        .catch(error => {
            window.analytics.track(VIDEO_PLAYER_WARNING, data);
        });
}
