import React from 'react';
import {Col, Row, Tooltip, Typography} from "antd";
import {REACT_APP_BASE_URL, REACT_APP_META_FB_APP_ID} from "../util/Constants";
import {
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    RedditIcon,
    RedditShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";

const { Paragraph } = Typography;

function ShareContainer(props) {
    return (
        <div>
            <Row gutter={[15,15]} align='middle'>
                <Col>
                    <Tooltip placement="topLeft" title="Share to Twitter">
                        <TwitterShareButton
                            url={REACT_APP_BASE_URL}
                            title={props.title + " on Rokfin"}
                            hashtags={['Rokfin']}
                        >
                            <TwitterIcon round size={37}/>
                        </TwitterShareButton>
                    </Tooltip>
                </Col>
                <Col>
                    <Tooltip placement="topLeft" title="Share to Facebook">
                        <FacebookShareButton
                            url={REACT_APP_BASE_URL}
                            quote={props.title + " on Rokfin"}
                        >
                            <FacebookIcon round size={37}/>
                        </FacebookShareButton>
                    </Tooltip>
                </Col>
                <Col>
                    <Tooltip title="Share via Facebook Messenger">
                        <FacebookMessengerShareButton
                            url={REACT_APP_BASE_URL}
                            appId={REACT_APP_META_FB_APP_ID}
                        >
                            <FacebookMessengerIcon round size={37}/>
                        </FacebookMessengerShareButton>
                    </Tooltip>
                </Col>
                <Col>
                    <Tooltip title="Share via WhatsApp">
                        <WhatsappShareButton
                            url={REACT_APP_BASE_URL}
                            title={props.title + " on Rokfin"}
                        >
                            <WhatsappIcon round size={37}/>
                        </WhatsappShareButton>
                    </Tooltip>
                </Col>
                <Col>
                    <Tooltip title="Share to Reddit">
                        <RedditShareButton
                            url={REACT_APP_BASE_URL}
                            title={props.title + " on Rokfin"}
                        >
                            <RedditIcon round bgStyle={{fill: '#FF5700'}} size={37}/>
                        </RedditShareButton>
                    </Tooltip>
                </Col>
                <Col>
                    <Paragraph style={{paddingTop: 7}} code copyable={{ text: REACT_APP_BASE_URL }}>
                        {REACT_APP_BASE_URL}
                    </Paragraph>
                </Col>
            </Row>
        </div>
    );
}

export default ShareContainer;
