import {SET_DATA, SET_URLS, STREAM_COMPLETE} from "../actions/ActionTypes";

const initialState = {
    postId: null,
    mediaId: null,
    contentType: 'livestream',
    premiumPlan: 1,

    title: null,
    description: [],

    creatorId: null,
    creatorName: null,
    creatorUsername: null,
    creatorAvatar: null,

    startTime: null,
    pollingInterval: 1000 * 60,
    streamComplete: false,

    url: null,
    backupUrl: null,
    fallbackUrl: null,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case SET_DATA:
            return {
                ...state,
                postId: action.payload.postId,
                mediaId: action.payload.mediaId,

                title: action.payload.title,
                description: action.payload.description,

                creatorId: action.payload.creatorId,
                creatorName: action.payload.creatorName,
                creatorUsername: action.payload.creatorUsername,
                creatorAvatar: action.payload.creatorAvatar,

                startTime: action.payload.startTime,
                pollingInterval: action.payload.pollingInterval,

                url: action.payload.url || state.url,
                backupUrl: action.payload.backupUrl || state.backupUrl,
                fallbackUrl: action.payload.fallbackUrl || state.fallbackUrl,
            };
        case SET_URLS:
            return {
                ...state,

                url: action.payload.url,
                backupUrl: action.payload.backupUrl,
                fallbackUrl: action.payload.fallbackUrl
            };
        case STREAM_COMPLETE:
            return {
                ...state,

                streamComplete: action.payload.streamComplete
            };
        default:
            return state;
    }
}
