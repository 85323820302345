import {SET_VIDEOS} from "../actions/ActionTypes";

const initialState = {
    isLoading: true,
    error: false,
    showCountdown: false,
    data: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case SET_VIDEOS:
            return {
                ...state,
                isLoading: false,
                showCountdown: action.payload.showCountdown,
                error: action.payload.error,
                data: action.payload.data,
            };
        default:
            return state;
    }
}
