import {SET_PPV_DATA} from "../actions/ActionTypes";

const initialState = {
    id: null,
    productId: null,
    price: 0,

    title: null,
    description: [],

    startTime: null,
    pollingInterval: 1000 * 60,

    creatorId: null,
    creatorName: null,
    creatorUsername: null,
    creatorAvatar: null,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case SET_PPV_DATA:
            return {
                id: action.payload.id,
                productId: action.payload.productId,
                price: action.payload.price,

                title: action.payload.title,
                description: action.payload.description,

                startTime: action.payload.startTime,
                pollingInterval: action.payload.pollingInterval,

                creatorId: action.payload.creatorId,
                creatorName: action.payload.creatorName,
                creatorUsername: action.payload.creatorUsername,
                creatorAvatar: action.payload.creatorAvatar,
            };
        default:
            return state;
    }
}
