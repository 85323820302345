import React from 'react';
import {Button, Col, Row, Space, Typography} from "antd";
import {formatDate, isSubscriber} from "../../util/Helpers";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {BANNER_BACKGROUND_IMAGE} from "../../util/Constants";
import OneClickBuy from "../subscribe/OneClickBuy";

const {Title, Paragraph} = Typography;

const bannerBackgroundStyle = {
    background: `url(${BANNER_BACKGROUND_IMAGE})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    padding: 10,
    height: '65vh',
    minHeight: 550,
};

function VideoPlayerPaywall(props) {
    return (
        <div style={{...bannerBackgroundStyle}}>
            <Row justify='center' align='middle' style={{height: '100%'}}>
                <Col className='banner-overlay'>
                    <Space direction='vertical' size='large'>
                        <img src={'https://d22z6rj5fefjac.cloudfront.net/assets/Logos/Horizontal_Logo_Teal.png'} alt='' width={250}/>
                        <Space direction='vertical'>
                            <Title level={1} style={{fontWeight: 'bold', margin: 0}}>
                                {props.data.title}
                            </Title>
                            <Paragraph strong style={{margin: 0}}>
                                - {formatDate(props.data.startTime)} -
                            </Paragraph>
                            <Title level={3} style={{margin: 0}}>
                                Available exclusively on Rokfin.
                            </Title>
                        </Space>
                        {isSubscriber(props.user.token) ?
                            <OneClickBuy/>
                            :
                            <Button block danger type='primary' size='large' onClick={() => props.history.push('/subscribe')}>
                                SUBSCRIBE
                            </Button>
                        }
                    </Space>
                </Col>
            </Row>
        </div>
    );
}


const mapStateToProps = state => ({
    user: state.UserReducer,
    data: state.PpvReducer
});

export default withRouter(connect(mapStateToProps, null)(VideoPlayerPaywall));
