import React from 'react';
import {Divider, Typography} from "antd";
import {connect} from "react-redux";
import CreatorInfo from "../CreatorInfo";
import ShareContainer from "../ShareContainer";
import {formatDate} from "../../util/Helpers";

const { Title, Paragraph, Text } = Typography;

function ContentWrapper(props) {
    return (
        <div>
            <Title level={3}>
                {props.data.title}
            </Title>
            {props.data.description.map((text, index) => <Paragraph key={index}>{text}</Paragraph>)}
            <Text>{formatDate(props.data.startTime)}</Text>
            <Divider/>
            <CreatorInfo/>
            <Divider/>
            <ShareContainer title={props.data.title}/>
        </div>
    );
}

const mapStateToProps = state => ({
    data: state.PpvReducer,
});

export default connect(mapStateToProps, null)(ContentWrapper);
