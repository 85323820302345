import React from 'react';
import {withKeycloak} from "@react-keycloak/web";
import {Row, Col, Button, Space} from "antd";
import {REACT_APP_BASE_URL} from "../../util/Constants";
import {triggerSignUpPageView} from "../../services/AnalyticsFunctions";
import {connect} from "react-redux";

function AppHeader(props) {
    return (
        <Row justify='space-between'>
            <Col>
                <a href="https://www.rokfin.com/" rel="noopener noreferrer" target="_blank" >
                    <img src={'https://d22z6rj5fefjac.cloudfront.net/assets/Logos/Horizontal_Logo_White.png'} alt='' height={28}/>
                </a>
            </Col>
            <Col>
                {props.keycloak.authenticated ?
                    <Space>
                        {props.data.fallbackUrl && <Button type="primary" onClick={() => window.location.reload(true)}>Refresh</Button>}
                        <Button onClick={() => props.keycloak.logout({redirectUri: REACT_APP_BASE_URL})}>Log Out</Button>
                    </Space>
                    :
                    <Space>
                        <Button onClick={() => props.keycloak.login({redirectUri: `${REACT_APP_BASE_URL}live`})}>Log In</Button>
                        <Button type="primary" onClick={() => {
                            triggerSignUpPageView(props.user.token);
                            props.keycloak.register({redirectUri: `${REACT_APP_BASE_URL}live`})
                        }}>Sign Up</Button>
                    </Space>
                }
            </Col>
        </Row>
    );
}

const mapStateToProps = state => ({
    user: state.UserReducer,
    data: state.AppReducer
});

export default withKeycloak(connect(mapStateToProps, null)(AppHeader));
