import React, {useEffect} from 'react';
import {
    Switch,
    Route
} from "react-router-dom";
import LivePage from "../pages/LivePage";
import Subscribe from "../pages/Subscribe";
import HomePage from "../pages/HomePage";
import NotFound from "../pages/NotFound";
import {PublicOnlyRoute} from "../components/common/PublicOnlyRoute";
import {PrivateRoute} from "../components/common/PrivateRoute";
import ThankYouPage from "../pages/ThankYouPage";
import {withRouter} from 'react-router-dom';

function Routes({location}) {
    useEffect(() => {
        window.analytics.page();
    }, [location]);

    return (
        <Switch>
            <PublicOnlyRoute exact path="/" component={HomePage}/>
            <PrivateRoute exact path="/live" component={LivePage}/>
            <PrivateRoute exact path="/subscribe" component={Subscribe}/>
            <PrivateRoute exact path="/thank_you" component={ThankYouPage}/>
            <Route component={NotFound}/>
        </Switch>
    );
}

export default withRouter(Routes);
