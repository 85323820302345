import React from 'react';
import {Typography, Button, Space, Row, Col} from "antd";
import AppFooter from "../components/layout/AppFooter";
import {connect} from "react-redux";
import {formatDate} from "../util/Helpers";
import {withKeycloak} from "@react-keycloak/web";
import {BANNER_BACKGROUND_IMAGE, REACT_APP_BASE_URL} from "../util/Constants";
import CreatorsList from "../components/CreatorsList";
import {triggerSignUpPageView} from "../services/AnalyticsFunctions";
import FeaturedItemsList from "../components/FeaturedItemsList";

const { Link, Paragraph, Title } = Typography;

const bannerBackgroundStyle = {
    background: `url(${BANNER_BACKGROUND_IMAGE})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    padding: 10,
    height: '80vh',
    minHeight: 550,
};

function HomePage(props) {

    return (
        <Space direction='vertical' size='large'>
            <div className="home-banner">
                <div style={{...bannerBackgroundStyle}}>
                    <Row justify='center' align='middle' style={{height: '100%'}}>
                        <Col className='banner-overlay'>
                            <div>
                                <Space direction='vertical' size='large'>
                                    <img src={'https://d22z6rj5fefjac.cloudfront.net/assets/Logos/Horizontal_Logo_Teal.png'} alt='' width={250}/>
                                    <Space direction='vertical'>
                                        <Title level={1} style={{fontWeight: 'bold', margin: 0}}>
                                            {props.data.title}
                                        </Title>
                                        <Paragraph strong style={{margin: 0}}>
                                            - {formatDate(props.data.startTime)} -
                                        </Paragraph>
                                        <Title level={3} style={{margin: 0}}>
                                            Available exclusively on Rokfin.
                                        </Title>
                                    </Space>
                                    <Space direction='vertical' size={20}>
                                        <Button block type='primary' size='large' style={{width: 200}} onClick={() => {
                                            triggerSignUpPageView(props.user.token);
                                            props.keycloak.register({redirectUri: `${REACT_APP_BASE_URL}live`})
                                        }}>
                                            SIGN UP NOW
                                        </Button>
                                        <Paragraph strong style={{margin: 0}}>
                                            Already have an account? <Link onClick={() => props.keycloak.login({redirectUri: `${REACT_APP_BASE_URL}live`})}>
                                            Log in.
                                        </Link>
                                        </Paragraph>
                                    </Space>
                                </Space>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <FeaturedItemsList/>
            <CreatorsList/>
            <AppFooter/>
        </Space>
    );
}

const mapStateToProps = state => ({
    user: state.UserReducer,
    data: state.PpvReducer
});

export default withKeycloak(connect(mapStateToProps, null)(HomePage));
