import { Card, Col, notification, Radio, Row, Space, Spin, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import PayPageCCForm from '../components/subscribe/PayPageCCForm'
import PayPageCcSelect from '../components/subscribe/PayPageCcSelect'
import { triggerCreatingSubscriptionFailed, triggerPaymentPageReached } from '../services/AnalyticsFunctions'
import { createSubscriptionPayment, getPaymentProfiles } from '../services/API'

const { Paragraph, Text } = Typography

function Subscribe(props) {
	const [product, setProduct] = useState("yearly10")
	const [customerId, setCustomerId] = useState(null)
	const [cards, setCards] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const [verifyingCard, setVerifyingCard] = useState(false)
	const [creatingSubscription, setCreatingSubscription] = useState(false)
	const [showCCSelect, toggleShowCCSelect] = useState(false)
	const [showCCForm, toggleShowCCForm] = useState(false)

	useEffect(() => {
		getPaymentProfiles()
			.then((response) => {
				if (response.length > 0) {
					setCards(response)
					setCustomerId(response[0].customer_id)
					toggleShowCCSelect(true)
				} else {
					toggleShowCCForm(true)
				}
				setIsLoading(false)
			})
			.catch((error) => {
				setIsLoading(false)
				toggleShowCCForm(true)
			})
	}, [])

	useEffect(() => {
		triggerPaymentPageReached(props.data.creatorId, props.data.creatorName, props.user.token)
	}, [props.data.creatorId, props.data.creatorName, props.user.token])

	if (isLoading) {
		return <Spin size="large" style={{ margin: "auto", width: "100vw", paddingTop: "50vh" }} />
	}

	function onSubmit(token, paymentProfileId) {
		let data = {
			customerId: customerId,
			paymentProfileId: paymentProfileId,
			token: token,

			creatorId: props.data.creatorId,
			creatorName: props.data.creatorName,

			mediaId: 11390, //props.videos.data[0].mediaId,
			postId: 61863, //props.videos.data[0].postId,
			postTitle: "Collegiate Wrestling Duals (Mat 1 Live Stream)", // props.videos.data[0].title,
			contentType: "stream", //props.videos.data[0].contentType,
			premiumPlan: 1, //props.videos.data[0].premiumPlan,

			pointOfEntry: "post",

			productHandle: product,
			productId: product,
			price: product === "yearly10" ? 149.9 : 14.99,

			ppvProductId: props.data.productId,
		}

		createSubscriptionPayment(data, props.user.token)
			.then((response) => {
				setCreatingSubscription(false)

				props.history.push("/thank_you")
			})
			.catch((error) => {
				console.error(error)

				setVerifyingCard(false)
				setCreatingSubscription(false)

				notification.error({
					top: 100,
					message: "Server Error Occurred",
					description: "Please try again",
				})

				triggerCreatingSubscriptionFailed(props.user.token)
			})
	}

	return (
		<div
			style={{
				textAlign: "center",
				paddingTop: 25,
				paddingBottom: 25,
				minHeight: "100vh",
				backgroundColor: "#000",
			}}
		>
			<Space size={20} direction="vertical" style={{ textAlign: "center" }}>
				<div>
					<img
						alt="ROKFIN"
						style={{ width: 200 }}
						src={"https://d22z6rj5fefjac.cloudfront.net/assets/Logos/Horizontal_Logo_White.png"}
					/>

					<Paragraph strong style={{ margin: 10 }}>
						Unlock all PREMIUM content.
					</Paragraph>

					<Row type="flex" align="middle" justify="center" gutter={10}>
						<Col>
							<img
								alt=""
								src={"https://d22z6rj5fefjac.cloudfront.net/assets/Icons/Visa.png"}
								height={20}
							/>
						</Col>
						<Col>
							<img
								alt=""
								src={"https://d22z6rj5fefjac.cloudfront.net/assets/Icons/Mastercard.svg"}
								height={20}
							/>
						</Col>
						<Col>
							<img
								src={"https://d22z6rj5fefjac.cloudfront.net/assets/Icons/American-Express.png"}
								alt=""
								height={20}
							/>
						</Col>
						<Col>
							<img
								alt=""
								src={"https://d22z6rj5fefjac.cloudfront.net/assets/Icons/Discover.jpg"}
								height={20}
							/>
						</Col>
					</Row>
				</div>

				<Spin
					spinning={verifyingCard || creatingSubscription}
					tip={verifyingCard ? "Verifying your card..." : "Charging your card..."}
				>
					<Card size="small" style={{ width: "100%", marginBottom: 20, padding: 10 }}>
						<Radio.Group value={product} onChange={(e) => setProduct(e.target.value)}>
							<Radio value="monthly10">$14.99 Monthly</Radio>
							<Radio value="yearly10">$149.90 Yearly</Radio>
						</Radio.Group>
					</Card>

					{props.data.productId && props.data.price && props.data.title && (
						<Card size="small" style={{ width: 330, marginBottom: 20, padding: 10 }}>
							<Text>
								For{" "}
								{product === "monthly10"
									? `$${(props.data.price + 14.99).toFixed(2)}`
									: `$${(props.data.price + 149.9).toFixed(2)}`}
								, you'll get {props.data.title} and a Rokfin{" "}
								{product === "monthly10" ? "monthly" : "annual"} subscription that auto-renews at{" "}
								{product === "monthly10" ? "$14.99" : "$149.90"} per{" "}
								{product === "monthly10" ? "month" : "year"}.
							</Text>
						</Card>
					)}

					{showCCSelect && (
						<PayPageCcSelect
							product={product}
							ppvPrice={props.data.price}
							cards={cards}
							onAddCard={() => {
								toggleShowCCForm(true)
								toggleShowCCSelect(false)
							}}
							onCreatingSubscription={(isCreatingSubscription) =>
								setCreatingSubscription(isCreatingSubscription)
							}
							onSubmit={(paymentProfileId) => onSubmit(null, paymentProfileId)}
						/>
					)}

					{showCCForm && (
						<PayPageCCForm
							product={product}
							ppvPrice={props.data.price}
							onCreatingSubscription={(isCreatingSubscription) =>
								setCreatingSubscription(isCreatingSubscription)
							}
							onVerifyingCard={(isVerifying) => setVerifyingCard(isVerifying)}
							onSubmit={(token) => onSubmit(token, null)}
						/>
					)}
				</Spin>

				<Text type="secondary">
					By clicking the “LET’S GO!” button below, you
					<br />
					agree to the{" "}
					<a
						href={"https://www.rokfin.com/tou"}
						rel="noopener noreferrer"
						target="_blank"
						style={{ marginRight: 5, textDecoration: "underline" }}
					>
						Rokfin Terms
					</a>
					and
					<a
						href={"https://www.rokfin.com/privacy"}
						rel="noopener noreferrer"
						target="_blank"
						style={{ marginLeft: 5, textDecoration: "underline" }}
					>
						Privacy Policy
					</a>
					,
					<br />
					and that{" "}
					<b>
						Rokfin will automatically continue
						<br />
						your membership and charge the membership <br />
						fee (currently $14.99/month) to your payment <br />
						method until you cancel.
					</b>
					<br />
					<br />
					You may cancel at any time to avoid future charges. <br />
					To cancel, go to Account and click
					<br />
					“Cancel Membership.”
				</Text>
			</Space>
		</div>
	)
}

const mapStateToProps = (state) => ({
	data: state.PpvReducer,
	user: state.UserReducer,
	videos: state.VideosReducer,
})

export default withRouter(connect(mapStateToProps, null)(Subscribe))

/*
class Subscribe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            serverError: false,
            verifyingCard: false,
            creatingSubscription: false
        };
        this.chargifyForm = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        triggerPaymentPageReached(this.props.data.creatorId, this.props.data.creatorName, this.props.user.token);

        this.chargify = new window.Chargify();

        this.chargify.load({
            publicKey: REACT_APP_CHARGIFY_PUBLIC_KEY,
            type: 'card',
            serverHost: REACT_APP_CHARGIFY_SERVER_HOST,
            hideCardImage: true,
            style: {
                field: {
                    font: 'Avenir',
                    //margin: '2px',
                    width: '99%',
                },
                input: {
                    border: '1px solid #C7CBDB',
                    font: 'Avenir',
                    width: '99%'
                },
                label: {
                    font: 'Avenir',
                    fontWeight: 500,
                    fontSize: '12px',
                    width: '99%',
                    padding: '0px',
                    margin: '0px',
                    color: '#fff'
                },
                message: {
                    font: 'Avenir'
                }
            },
            fields: {
                number: {
                    label: 'CARD NUMBER',
                    selector: '#chargify2',
                    placeholder: 'XXXX-XXXX-XXXX-XXXX',
                    required: true,
                    message: 'Card number is not valid.'
                },
                month: {
                    label: 'MONTH',
                    selector: '#chargify2',
                    placeholder: 'MM',
                    required: true,
                    message: 'Month is not valid.'
                },
                year: {
                    label: 'YEAR',
                    selector: '#chargify2',
                    placeholder: 'YYYY',
                    required: true,
                    message: 'Year is not valid.'
                },

                cvv: {
                    label: 'CVV',
                    selector: '#chargify2',
                    placeholder: 'Security Code (CVV)',
                    required: true,
                    message: 'CVV is not valid.'
                },

                zip: {
                    selector: '#chargify3', //chargify3
                    label: 'ZIP CODE',
                    placeholder: 'Billing Zip',
                    required: false, //true,
                    message: 'This field is not valid. Please update it.',
                    maxlength: '6',
                    style: {
                        field: {
                            display: 'none'
                        },
                    }
                }

            }
        });
    }

    componentWillUnmount() {
        this.chargify.unload();
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({
            verifyingCard: true
        });

        triggerPaymentFormSubmitted(this.props.user.token);

        this.chargify.token(
            this.chargifyForm.current,

            (token) => {

                triggerCardVerified(this.props.user.token);

                this.setState({
                    verifyingCard: false,
                    creatingSubscription: true
                });

                let data = {
                    token: token,
                    creatorId: this.props.data.creatorId,
                    creatorName: this.props.data.creatorName,
                    mediaId: this.props.data.mediaId,
                    postId: this.props.data.postId,
                    postTitle: this.props.data.title,
                    contentType: "livestream",
                    premiumPlan: 1,
                    productId: "monthly10",
                    price: 9.99,
                    pointOfEntry: "post",
                }

                createSubscriptionPayment(data, this.props.user.token)
                    .then(response => {
                        this.setState({ creatingSubscription: false });

                        this.props.history.push("/thank_you");
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            verifyingCard: false,
                            creatingSubscription: false
                        });
                        notification.error({
                            top: 100,
                            message: "Server Error Occurred",
                            description: "Please try again"
                        });

                        triggerCreatingSubscriptionFailed(this.props.user.token);
                    });
            },

            (error) => {
                console.log(error);
                this.setState({
                    verifyingCard: false,
                    creatingSubscription: false
                });
                notification.error({
                    top: 100,
                    message: "Error Verifying Card",
                    description: "Please check your card and try again"
                });
                triggerCardVerificationFailed(this.props.user.token);
            }
        );
    }

    render() {
        return (
            <div style={{width: 300, margin: 'auto', textAlign: 'center', marginTop: 50}}>
                <Spin spinning={this.state.verifyingCard || this.state.creatingSubscription} tip={this.state.verifyingCard ? "Verifying your card..." : "Creating your subscription..."}>

                    <img src={'https://d22z6rj5fefjac.cloudfront.net/assets/Logos/Horizontal_Logo_White.png'} alt="ROKFIN" style={{width: 200}}/>

                    <div style={{marginTop: 10, marginBottom: 10}}>
                        <Text strong>
                            Set up your credit or debit card.
                        </Text>
                    </div>

                    <Space style={{marginBottom: 50}}>
                        <img alt="" src={'https://d22z6rj5fefjac.cloudfront.net/assets/Icons/Visa.png'} height={21}/>
                        <img alt="" src={'https://d22z6rj5fefjac.cloudfront.net/assets/Icons/Mastercard.svg'} height={21}/>
                        <img alt="" src={'https://d22z6rj5fefjac.cloudfront.net/assets/Icons/American-Express.png'} height={21}/>
                        <img alt="" src={'https://d22z6rj5fefjac.cloudfront.net/assets/Icons/Discover.jpg'} height={21}/>
                    </Space>

                    <form onSubmit={this.handleSubmit} ref={this.chargifyForm}>
                        <div>
                            <div id="chargify2"></div>
                            <div id="chargify2"></div>
                            <div id="chargify2"></div>
                            <div id="chargify2"></div>
                            <div id="chargify3"></div>
                        </div>

                        <Text style={{marginTop: 0, paddingTop: 0}}>
                            Unlimited access to all premium content
                        </Text>
                        <div style={{marginBottom: 10}}>
                            <Text strong>
                                TOTAL: $9.99/MONTH
                            </Text>
                        </div>

                        <Button block type="primary" size="large" disabled={this.state.verifyingCard || this.state.creatingSubscription} htmlType="submit">
                            LET'S GO!
                        </Button>
                    </form>

                    <div style={{marginTop: 10}}>
                        <Text>
                            No commitments. Cancel online at any time. <br/>
                            By selecting ‘LET'S GO!’ you agree to <br/>
                            <Space size='small'>
                                <Link underline href={"https://www.rokfin.com/tou"} rel="noopener noreferrer" target="_blank">
                                    Rokfin Terms
                                </Link>
                                and
                                <Link underline href={"https://www.rokfin.com/privacy"} rel="noopener noreferrer" target="_blank">
                                    Privacy Policy.
                                </Link>
                            </Space>
                        </Text>
                    </div>
                </Spin>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    data: state.AppReducer,
    user: state.UserReducer
});

export default withRouter(connect(mapStateToProps, null)(Subscribe));


 */
