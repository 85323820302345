import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {setVideos} from "../../redux/actions/Actions";
import {getVideos} from "../../services/API";
import {Button, Col, Divider, List, Result, Row, Spin} from "antd";
import VideoPlayerItem from "./VideoPlayerItem";
import EventCountdown from "../videoPlayer/EventCountdown";
import {triggerPostPageView} from "../../services/AnalyticsFunctions";
import {getUrlParams} from "../../util/Helpers";
import VideoPlayerPaywall from "../videoPlayer/VideoPlayerPaywall";

function VideoPlayerList(props) {
    const [canViewEvent, setCanViewEvent] = useState(false);

    const {setVideos} = props;

    useEffect(() => {
        if (props.user.token) {
            getVideos(props.user.token)
                .then(response => {
                    setCanViewEvent(response.canViewEvent);

                    setVideos({data: response.posts, error: false});

                    response.posts.forEach(item => (
                        triggerPostPageView(
                            item.creatorId,
                            item.postId,
                            item.contentType,
                            item.mediaId,
                            getUrlParams(window.location.search, 'affiliate_id'),
                            item.premiumPlan,
                            item.title,
                            props.user.token
                        )
                    ))
                })
                .catch(error => {
                    console.error(error);
                    setVideos({data: [], error: true});
                })
        }
    }, [props.user.token, setVideos]);

    if (!canViewEvent) {
        return <VideoPlayerPaywall/>
    }

    if (props.videos.error) {
        return (
            <div>
                <Result
                    status="500"
                    title="We seem to be a little too popular right now."
                    subTitle="Try refreshing the page. If the error persists, please email support (support@rokfin.com)."
                    extra={<Button size='large' onClick={() => window.location.reload(true)} type="primary">Refresh Page</Button>}
                />
                <Divider/>
            </div>
        )
    }

    if (props.videos.isLoading) {
        return (
            <Row align='center' justify='middle' style={{height: '100vh', width: '100%'}}>
                <Col>
                    <Spin size='large'/>
                </Col>
            </Row>
        )
    }

    if (props.videos.showCountdown) {
        return <EventCountdown/>;
    }

    return (
        <List
            grid={{
                xs: 1,
                sm: 1,
                md: 1,
                lg: 2,
                xl: 2,
                xxl: 2,
                gutter: 20
            }}
            dataSource={props.videos.data}
            renderItem={(item) => (
                <List.Item>
                    <VideoPlayerItem video={item}/>
                </List.Item>
            )}
        />
    );
}

const mapStateToProps = state => ({
    user: state.UserReducer,
    videos: state.VideosReducer
});

export default connect(mapStateToProps, {setVideos})(VideoPlayerList);
