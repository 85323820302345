import {SET_USER, SET_NAME} from "../actions/ActionTypes";

const initialState = {
    token: null,
    id: null,
    email: null,
    username: null,
    roles: [],

    // Casey will get to these if he has time
    avatar: null,
    name: null,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case SET_USER:

            return {
                ...state,
                ...action.payload
            };
        case SET_NAME:
            return {
                ...state,
                name: action.payload.name
            };
        default:
            return state;
    }
}
