import React, {useState, useEffect} from 'react';
import {Card} from "antd";
import ReactJWPlayer from "react-jw-player";
import {
    REACT_APP_PLAYER_LICENSE_KEY,
    REACT_APP_PLAYER_SCRIPT,
    VIDEO_PLAYER_ERROR_MESSAGES
} from "../../util/Constants";
import {
    triggerBufferingStarted,
    triggerContentReached30Seconds, triggerVideoPlaybackError,
    triggerVideoPlaybackWarning
} from "../../services/AnalyticsFunctions";
import {connect} from "react-redux";
import {setStreamComplete, setUrls} from "../../redux/actions/Actions";
import VideoPlayerStopped from "../videoPlayer/VideoPlayerStopped";

function VideoPlayerItem(props) {
    const [streamStopped, setStreamStopped] = useState(false);

    useEffect(() => {
        if (props.user.token) {
            triggerContentReached30Seconds({
                    content_type: props.video.contentType,
                    creator_id: props.video.creatorId,
                    creator_name: props.video.creatorName,
                    premium_plan: props.video.premiumPlan,

                    media_id: props.video.mediaId,
                    post_id: props.video.postId,
                    post_title: props.video.title,
                },
                props.user.token
            )
        }
    }, [props.video, props.user.token]);



    function handleError(event) {
        triggerVideoPlaybackError(event, props.user.token);

        setStreamStopped(true);
    }

    if (streamStopped) {
        return <VideoPlayerStopped/>
    }

    return (
        <Card
            size='small'
            bordered={false}
            title={props.video.title}
        >
            <div className='video-player-container'>
                <div className='video-player'>
                    <ReactJWPlayer
                        playerId={props.video.mediaId.toString()}
                        playerScript={REACT_APP_PLAYER_SCRIPT}
                        licenseKey={REACT_APP_PLAYER_LICENSE_KEY}
                        file={props.video.url}
                        aspectRatio="16:9"
                        isAutoPlay={true}
                        isMuted={false}
                        onBuffer={(info) => triggerBufferingStarted(info, props.video.creatorId, props.video.mediaId, props.video.postId, props.video.contentType, props.user.token)}
                        onError={(event) => handleError(event)}
                        onWarning={(event) => triggerVideoPlaybackWarning(event, props.user.token)}
                        customProps={{
                            skin: { name: 'custom' },
                            ga: {},
                            cast: {},
                            liveTimeout: 0,
                            ...VIDEO_PLAYER_ERROR_MESSAGES
                        }}
                    />
                </div>
            </div>
        </Card>
    );
}

const mapStateToProps = state => ({
    data: state.AppReducer,
    user: state.UserReducer
});

export default connect(mapStateToProps, {setUrls, setStreamComplete})(VideoPlayerItem);
