import React from 'react';
import {Layout} from "antd";
import ContentWrapper from "../components/layout/ContentWrapper";
import VideoPlayerList from "../components/videoPlayerList/VideoPlayerList";

const { Content } = Layout;

function LivePage() {
    return (
        <Content className='content'>
            <VideoPlayerList/>
            <ContentWrapper/>
        </Content>
    );
}

export default LivePage;
