import { PlusOutlined } from "@ant-design/icons"
import { Button, Card, Col, Divider, Form, Row, Select, Typography } from "antd"
import React, { useEffect } from "react"

const { Option } = Select
const { Title, Text } = Typography

const layout = {
	labelCol: { span: 0 },
	wrapperCol: { span: 24 },
}

function PayPageCcSelect(props) {
	const [form] = Form.useForm()

	useEffect(() => {
		if (props.cards.length > 0) {
			form.setFieldsValue({
				paymentProfileId: props.cards[0].id,
			})
		}
	}, [form, props.cards, props.id])

	return (
		<Card
			size="small"
			bodyStyle={{
				paddingBottom: 15,
				width: 330,
				paddingTop: 10,
				paddingLeft: 10,
				paddingRight: 10,
			}}
		>
			<Form
				name="pay-page-form"
				form={form}
				onFinish={(values) => {
					props.onCreatingSubscription(true)
					props.onSubmit(values.paymentProfileId)
				}}
				{...layout}
			>
				<Form.Item
					name="paymentProfileId"
					rules={[{ required: true, message: "Please select a card." }]}
					style={{ marginBottom: 0 }}
				>
					<Select
						size="large"
						style={{ width: "100%" }}
						placeholder="Select a card..."
						dropdownRender={(menu) => (
							<div>
								{menu}
								<div style={{ padding: 5 }}>
									<Button block icon={<PlusOutlined />} onClick={props.onAddCard}>
										New Card
									</Button>
								</div>
							</div>
						)}
					>
						{props.cards.map((item) => (
							<Option key={item.id} value={item.id} style={{ color: "#000" }}>
								{item.masked_card_number}
							</Option>
						))}
					</Select>
				</Form.Item>

				<Divider style={{ marginTop: 15, marginBottom: 15 }} />

				<Row justify="space-between">
					<Col>
						<Title level={4}>Total billed today:</Title>
					</Col>
					<Col>
						{props.ppvPrice ? (
							<Title level={4}>
								{props.product === "yearly10"
									? `$${(props.ppvPrice + 149.9).toFixed(2)}`
									: `$${(props.ppvPrice + 14.99).toFixed(2)}`}
							</Title>
						) : (
							<>
								{props.product === "yearly10" ? (
									<Title level={4}>
										$149.90{" "}
										<Text style={{ fontSize: 12 }} type="success">
											(2 months free)
										</Text>
									</Title>
								) : (
									<Title level={4}>$14.99</Title>
								)}
							</>
						)}
					</Col>
				</Row>

				<Divider style={{ marginTop: 5, marginBottom: 15 }} />

				<Form.Item style={{ marginBottom: 0 }}>
					<Button block size="large" type="primary" onClick={() => form.submit()}>
						LET'S GO!
					</Button>
				</Form.Item>
			</Form>
		</Card>
	)
}

export default PayPageCcSelect
