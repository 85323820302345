import React from 'react';
import {Row, Col, Avatar, Typography} from "antd";
import {connect} from "react-redux";

const { Text } = Typography;

function CreatorInfo(props) {
    return (
        <Row align="middle" gutter={15}>
            <Col>
                <Avatar src={props.data.creatorAvatar} size='large'/>
            </Col>
            <Col>
                <div>
                    <Text strong style={{color: '#fff'}}>
                        {props.data.creatorName}
                    </Text>
                </div>
                <div>
                    <Text>
                        @{props.data.creatorUsername}
                    </Text>
                </div>
            </Col>
        </Row>
    );
}

const mapStateToProps = state => ({
    data: state.PpvReducer
});

export default connect(mapStateToProps, null)(CreatorInfo);
