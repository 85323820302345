import { Button, Card, Col, Divider, notification, Row, Typography } from "antd"
import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
	triggerCardVerificationFailed,
	triggerCardVerified,
	triggerPaymentFormSubmitted,
} from "../../services/AnalyticsFunctions"
import { REACT_APP_CHARGIFY_PUBLIC_KEY, REACT_APP_CHARGIFY_SERVER_HOST } from "../../util/Constants"

const { Text, Title } = Typography

class PayPage extends Component {
	constructor(props) {
		super(props)
		this.chargifyForm = React.createRef()
		this.handleSubmit = this.handleSubmit.bind(this)
	}

	componentDidMount() {
		this.chargify = new window.Chargify()

		this.chargify.load({
			publicKey: REACT_APP_CHARGIFY_PUBLIC_KEY,
			type: "card",
			serverHost: REACT_APP_CHARGIFY_SERVER_HOST,
			hideCardImage: true,
			style: {
				field: {},
				input: {},
				label: {
					color: "#ccc",
					fontWeight: 500,
					fontSize: "12px",
					padding: "0px",
					margin: "0px 2px",
				},
				message: {},
			},
			fields: {
				number: {
					label: "CARD NUMBER",
					selector: "#chargify2",
					placeholder: "XXXX-XXXX-XXXX-XXXX",
					required: true,
					message: "Card number is not valid.",
				},
				month: {
					label: "MONTH",
					selector: "#chargify2",
					placeholder: "MM",
					required: true,
					message: "Month is not valid.",
				},
				year: {
					label: "YEAR",
					selector: "#chargify2",
					placeholder: "YYYY",
					required: true,
					message: "Year is not valid.",
				},

				cvv: {
					label: "CVV",
					selector: "#chargify2",
					placeholder: "Security Code (CVV)",
					required: true,
					message: "CVV is not valid.",
				},
				zip: {
					selector: "#chargify3",
					label: "Zip Code",
					placeholder: "Billing Zip",
					required: false, //true,
					message: "This field is not valid. Please update it.",
					maxlength: "6",
					style: {
						field: {
							display: "none",
						},
					},
				},
			},
		})
	}

	componentWillUnmount() {
		this.chargify.unload()
	}

	handleSubmit(e) {
		e.preventDefault()

		this.props.onVerifyingCard(true)

		triggerPaymentFormSubmitted(this.props.user.token)

		this.chargify.token(
			this.chargifyForm.current,

			(token) => {
				triggerCardVerified(this.props.user.token)

				this.props.onVerifyingCard(false)
				this.props.onCreatingSubscription(true)

				this.props.onSubmit(token)
			},

			(error) => {
				this.props.onVerifyingCard(false)
				this.props.onCreatingSubscription(false)

				notification.error({
					top: 100,
					message: "Rokfin",
					description: "Error verifying card. Please try again.",
				})

				triggerCardVerificationFailed(this.props.user.token)
			}
		)
	}

	render() {
		return (
			<Card
				size="small"
				bodyStyle={{
					paddingTop: 10,
					paddingRight: 10,
					paddingLeft: 10,
					paddingBottom: 15,
					width: 330,
				}}
			>
				<form onSubmit={this.handleSubmit} ref={this.chargifyForm} style={{ minHeight: 265 }}>
					<div>
						<div id="chargify2"></div>
						<div id="chargify2"></div>
						<div id="chargify2"></div>
						<div id="chargify2"></div>
						<div id="chargify3"></div>
					</div>

					<Divider style={{ marginTop: 0, marginBottom: 15 }} />

					<Row justify="space-between">
						<Col>
							<Title level={4}>Total billed today:</Title>
						</Col>
						<Col>
							{this.props.ppvPrice ? (
								<Title level={4}>
									{this.props.product === "yearly10"
										? `$${(this.props.ppvPrice + 149.9).toFixed(2)}`
										: `$${(this.props.ppvPrice + 14.99).toFixed(2)}`}
								</Title>
							) : (
								<>
									{this.props.product === "yearly10" ? (
										<Title level={4}>
											$149.90{" "}
											<Text style={{ fontSize: 12 }} type="success">
												(2 months free)
											</Text>
										</Title>
									) : (
										<Title level={4}>$14.99</Title>
									)}
								</>
							)}
						</Col>
					</Row>

					<Divider style={{ marginTop: 5, marginBottom: 15 }} />

					<Button block htmlType="submit" type="primary" size="large">
						LET'S GO!
					</Button>
				</form>
			</Card>
		)
	}
}

const mapStateToProps = (state) => ({
	data: state.PpvReducer,
	user: state.UserReducer,
})

export default withRouter(connect(mapStateToProps, {})(PayPage))
