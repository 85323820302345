export const SET_DATA = 'SET_DATA';
export const SET_URLS = 'SET_URLS';
export const SET_VIDEOS = 'SET_VIDEOS';
export const STREAM_COMPLETE = 'STREAM_COMPLETE';
export const SET_PPV_DATA = 'SET_PPV_DATA';

// Deprecated
export const SET_USER = 'SET_USER';
export const SET_NAME = 'SET_NAME';
export const SET_AUTOSCROLL = 'SET_AUTOSCROLL';
export const ADD_COMMENT = 'ADD_COMMENT';
export const ADD_COMMENTS = 'ADD_COMMENTS';
