import { combineReducers } from "redux";
import AppReducer from "./AppReducer";
import UserReducer from "./UserReducer";
import CommentReducer from "./CommentReducer";
import VideosReducer from "./VideosReducer";
import PpvReducer from "./PpvReducer";

export default combineReducers({
    AppReducer,
    UserReducer,
    CommentReducer,
    VideosReducer,
    PpvReducer,
});
