import React from 'react';
import ReactDOM from 'react-dom';
import App from './modules/App';
import TagManager from 'react-gtm-module';
import * as serviceWorker from './serviceWorker';
import * as atatus from 'atatus-spa';
import * as Sentry from '@sentry/react';
import keycloak from "./modules/keycloak";
import {Provider} from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";
import {KeycloakProvider} from "@react-keycloak/web";
import configureStore from "./redux/store/ConfigureStore";
import {
    REACT_APP_ATATUS_API_KEY,
    REACT_APP_SENTRY_ENVIRONMENT,
    REACT_APP_SENTRY_DSN,
    REACT_APP_GTM_ID,
    REACT_APP_GTM_ENVIRONMENT_AUTH,
    REACT_APP_GTM_PREVIEW
} from "./util/Constants";
import './index.css';

// Initialize Sentry
Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    environment: REACT_APP_SENTRY_ENVIRONMENT,
});

// Initialize Atatus
atatus.config(REACT_APP_ATATUS_API_KEY).install();

// Initialize GTM
TagManager.initialize({
    gtmId: REACT_APP_GTM_ID,
    auth: REACT_APP_GTM_ENVIRONMENT_AUTH,
    preview: REACT_APP_GTM_PREVIEW
});

const store = configureStore();

ReactDOM.render(
    <KeycloakProvider
        initConfig={{
            onLoad: 'check-sso',
            promiseType: "native",
            silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
        }}
        autoRefreshToken={true}
        keycloak={keycloak}
        LoadingComponent={<div style={{backgroundColor: '#000', width: '100vw', height: '100vh'}}/>}
    >
        <Provider store={store}>
            <Router>
                <App />
            </Router>
        </Provider>
    </KeycloakProvider>
    , document.getElementById('root'));

serviceWorker.unregister();
