import React, {useState, useEffect} from 'react';
import {Button, Form, notification, Select} from "antd";
import {useHistory, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getPaymentProfiles, payForPPV} from "../../services/API";

function OneClickBuy(props) {
    let history = useHistory();
    const [form] = Form.useForm();
    const [cards, setCards] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (cards.length === 0) {
            getPaymentProfiles(props.user.token)
                .then(response => {
                    setCards(response);

                    if (response.length > 0) {
                        form.setFieldsValue({
                            paymentProfileId: response[0].id,
                        })
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [cards, form, props.user.token]);

    function onOneClickBuy(values) {

        setIsLoading(true);

        let data = {
            product_id: props.data.productId,
            post_id: 61863 , //props.videos.data[0].postId,
            payment_profile_id: values.paymentProfileId
        };

        payForPPV({data: data, token: props.user.token})
            .then(response => {
                setIsLoading(false);
                history.push(`/thank_you`);

            })
            .catch(error => {
                console.error(error);
                setIsLoading(false);
                notification.error({
                    top: 100,
                    message: 'Rokfin',
                    description: 'Something went wrong. Please try again.'
                });
            });
    }

    return (
        <div>
            <Form
                name="one-click-buy-form"
                form={form}
                onFinish={onOneClickBuy}
            >
                <Form.Item
                    name="paymentProfileId"
                    rules={[{ required: true, message: 'Please select a card.' }]}
                >
                    <Select
                        size='large'
                        style={{width: '100%'}}
                        placeholder="Select a card"
                        dropdownRender={menu => (
                            <div>
                                {menu}
                            </div>
                        )}
                    >
                        {cards.map(item => (
                            <Select.Option
                                key={item.id}
                                value={item.id}
                                style={{color: '#000'}}
                            >
                                {item.masked_card_number}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button
                        block
                        danger
                        size='large'
                        type='primary'
                        htmlType="submit"
                        loading={isLoading}
                    >
                        1-Click Buy
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

const mapStateToProps = state => ({
    data: state.PpvReducer,
    user: state.UserReducer,
    videos: state.VideosReducer
});

export default withRouter(connect(mapStateToProps, {})(OneClickBuy));
