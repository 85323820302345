import React from 'react';
import {Card, List, Typography, Space} from "antd";
import {FEATURED_LIST_TITLE, FEATURED_LIST_ITEMS} from "../util/Constants";

const {Text, Title} = Typography;

function FeaturedItemsList() {
    return (
        <Space direction='vertical' size='large'>
            <Title level={3} style={{marginLeft: 20, marginBottom: 0}}>
                {FEATURED_LIST_TITLE}
            </Title>
            <List
                grid={{
                    xs: 1,
                    sm: 2,
                    md: 3,
                    lg: 3,
                    xl: 5,
                    xxl: 5,
                }}
                dataSource={FEATURED_LIST_ITEMS}
                renderItem={item => (
                    <List.Item>
                        <div className='card-container'>
                            <Card
                                hoverable
                                bordered={false}
                                cover={<img alt="" src={item.url} />}
                            >
                                <div style={{paddingTop: 10, paddingBottom: 10}}>
                                    <Text strong>
                                        {item.title}
                                    </Text>
                                </div>
                            </Card>
                        </div>
                    </List.Item>
                )}
            />
        </Space>
    );
}

export default FeaturedItemsList;
