import {
    REACT_APP_BASE_API_URL,
    REACT_APP_CORE_BASE_API_URL,
    REACT_APP_DONATE_API_BASE_URL,
    REACT_APP_PAYMENT_API_BASE_URL
} from "../util/Constants";
import Cookies from 'js-cookie';

const request = (options, token) => {
    const headers = new Headers({
        'Content-Type': 'application/json'
    });

    if (token) {
        headers.append('Authorization', 'Bearer ' + token);
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then(response =>
            response.json().then(json => {
                if(!response.ok) {
                    return Promise.reject(json);
                }
                return json;
            })
        );
};

export function getPaymentProfiles(token) {
    return request({
        url: `${REACT_APP_DONATE_API_BASE_URL}/profiles`,
        method: 'GET'
    }, token);
}

export function payForPPV({data, token}) {
    return request({
        url: `${REACT_APP_PAYMENT_API_BASE_URL}/v1/ppv`,
        method: 'POST',
        body: JSON.stringify(data)
    }, token);
}

export function createSubscriptionPayment(data, token) {
    return request({
            url: `${REACT_APP_PAYMENT_API_BASE_URL}/v1/subscription`,
            method: 'POST',
            body: JSON.stringify(data),
        }, token);
}

export function analyticsTrackEvent(event, data, token) {
    var cookie = Cookies.get('ajs_anonymous_id')

    if(typeof cookie === 'undefined') cookie = 'unknown_anonymous_id'
    else cookie = cookie.replace(/['"]+/g, '')

    return request({
            url: `${REACT_APP_CORE_BASE_API_URL}/public/analytics/trk?event=${event}&anonymousId=${cookie}`,
            method: 'POST',
            body: JSON.stringify(data)
        },
        token
    );
}

export function authenticatedTrackEvent(event, data, token) {
    return request({
            url: `${REACT_APP_CORE_BASE_API_URL}/analytics/trk/authenticated?event=${event}`,
            method: 'POST',
            body: JSON.stringify(data)
        },
        token
    );
}


export function getPpvData() {
    return request({
        url: REACT_APP_BASE_API_URL + "/data/ppv/all",
        method: 'GET',
    });
}

export function getVideos(token) {
    return request({
            url: REACT_APP_BASE_API_URL + "/data/ppv/url",
            method: 'GET',
        },
        token
    );
}

/*
async function dummyPpvDataRequest() {
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            resolve({
                id: 1,
                productId: 'ppv/1:263039',
                price: 10,
                canViewEvent: false,

                title: "Collegiate Wrestling Duals",
                description: [
                    "Iowa and Penn State lead a monster field as the National Duals will once again be contested."
                ],

                startTime: "2021-12-20T12:00:00.000-04:00",
                pollingInterval: 1000 * 15,

                creatorId: 71205,
                creatorName: "Collegiate Duals",
                creatorUsername: "CollegiateDuals",
                creatorAvatar: "https://img.production.rokfin.com/eyJidWNrZXQiOiJya2ZuLXByb2R1Y3Rpb24tbWVkaWEiLCJrZXkiOiIvdXNlci8yNjMwMzkvcHJvZmlsZS9hdmF0YXIvOWVhNzY0ZTctNTNjMi00NGQ5LThiMzMtYWRhNWJiMjYzN2Q5IiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjoxMjAsImhlaWdodCI6MTIwLCJmaXQiOiJjb3ZlciJ9fX0=",
            });
        }, 1);
    });
}

async function dummyVideosRequest() {
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            resolve([
                {
                    post_id: 1,
                    media_id: 1,
                    title: "Mat 1",
                    url: 'https://wowzaec2demo.streamlock.net/live/bigbuckbunny/playlist.m3u8',

                    content_type: 'livestream',
                    premium_plan: 1,

                    creator_id: 1,
                    creator_name: 'Creator Name',
                    creator_username: 'creatorUsername',
                },
                {
                    post_id: 2,
                    media_id: 2,
                    title: "Mat 2",
                    url: 'https://wowzaec2demo.streamlock.net/live/bigbuckbunny/playlist.m3u8',

                    content_type: 'livestream',
                    premium_plan: 1,

                    creator_id: 1,
                    creator_name: 'Creator Name',
                    creator_username: 'creatorUsername',
                },
            ]);
        }, 2);
    });
}

 */

/*

export function getData() {
    return request({
        url: REACT_APP_BASE_API_URL + "/data/all",
        method: 'GET',
    });
}

export function getUrl(token) {
    return request({
            url: REACT_APP_BASE_API_URL + "/data/url",
            method: 'GET',
        },
        token
    );
}
 */

/*
async function dummyDataRequest() {
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            resolve({
                postId: 22827,
                mediaId: 2886,

                title: "Collegiate Wrestling Duals",
                description: [
                    "Iowa and Penn State lead a monster field as the National Duals will once again be contested."
                ],

                creatorId: 71205,
                creatorName: "Collegiate Duals",
                creatorUsername: "CollegiateDuals",
                creatorAvatar: "https://img.production.rokfin.com/eyJidWNrZXQiOiJya2ZuLXByb2R1Y3Rpb24tbWVkaWEiLCJrZXkiOiIvdXNlci8yNjMwMzkvcHJvZmlsZS9hdmF0YXIvOWVhNzY0ZTctNTNjMi00NGQ5LThiMzMtYWRhNWJiMjYzN2Q5IiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjoxMjAsImhlaWdodCI6MTIwLCJmaXQiOiJjb3ZlciJ9fX0=",

                startTime: "2021-12-20T11:00:00.000-04:00",
                pollingInterval: 1000 * 15,

                //new Date(new Date().getTime() - 1000 * 50000)

                //url: 'https://wowzaec2demo.streamlock.net/live/bigbuckbunny/playlist.m3u8',
                //backup: 'https://cdn.jwplayer.com/manifests/7RtXk3vl.m3u8',
                //fallback: 'https://youtu.be/ptxAslIwvYI'
            });
        }, 1);
    });
}


async function dummyUrlRequest() {
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            resolve({
                url: 'https://wowzaec2demo.streamlock.net/live/bigbuckbunny/playlist.m3u8',
                //backup: 'https://cdn.jwplayer.com/manifests/7RtXk3vl.m3u8',
                //fallback: 'https://youtu.be/ptxAslIwvYI'
            });
        }, 1);
    });
}
 */

