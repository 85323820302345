import React, {useEffect} from 'react';
import {Result, Button, Typography} from 'antd';
import {withRouter} from 'react-router-dom';
import { CheckCircleFilled } from '@ant-design/icons';
import {useKeycloak} from "@react-keycloak/web";
import {connect} from "react-redux";

const {Title, Paragraph} = Typography

function ThankYouPage(props) {
    const [keycloak] = useKeycloak();

    useEffect(() => {
        keycloak.updateToken(100000000);
    }, [keycloak]);

    return (
        <Result
            style={{paddingTop: 100, maxWidth: 720, margin: 'auto'}}
            icon={<CheckCircleFilled style={{color: '#08AD9F'}}/>}
            status="success"
            title={
                <Title level={2}>
                    Thank you for subscribing.
                </Title>
            }
            subTitle={
                <Paragraph strong>
                    Rokfin gives creators like {props.data.creatorUsername} an easy way to earn more money through a subscription service.
                    By sharing the value of the network, Rokfin is changing the future of how creativity
                    is made and funded. Keep helping them #MakeIt.
                </Paragraph>
            }
            extra={[
                <Button type="primary" size='large' key="1" onClick={() => props.history.push('/live')}>
                    Continue to Event
                </Button>,
            ]}
        />
    );
}

const mapStateToProps = state => ({
    data: state.PpvReducer
});

export default withRouter(connect(mapStateToProps, null)(ThankYouPage));
