import React, {useEffect, useState} from 'react';
import {useKeycloak} from "@react-keycloak/web";
import {connect} from "react-redux";
import {Button, Result, Layout, Spin} from "antd";
import {getPpvData} from "../services/API";
import {setUser, setPpvData} from "../redux/actions/Actions";
import AppHeader from "../components/layout/AppHeader";
import Routes from "./Routes";
import './App.less';
import {triggerPpvPageView} from "../services/AnalyticsFunctions";

const { Header } = Layout;

function App(props) {
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [keycloak] = useKeycloak();

    const {setUser, setPpvData} = props;

    useEffect(() => {
        if (keycloak.tokenParsed && keycloak.token) {
            setUser(keycloak.tokenParsed, keycloak.token);
        }
    }, [keycloak.tokenParsed, keycloak.token, setUser]);

    useEffect(() => {
        getPpvData()
            .then(response => {
                setPpvData({
                    id: response.id,
                    productId: response.productId,
                    price: response.price,

                    title: response.title,
                    description: response.description,

                    startTime: new Date(response.startTime),
                    pollingInterval: response.pollingInterval,

                    creatorId: response.creatorId,
                    creatorName: response.creatorName,
                    creatorUsername: response.creatorUsername,
                    creatorAvatar: response.creatorAvatar,
                });

                triggerPpvPageView(response.id, response.title, response.productId, response.canViewEvent, response.price);

                setIsLoading(false);

            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
                setIsError(true);
            })
    }, [setPpvData]);

    if (isLoading) {
        return <Spin size='large' style={{margin: 'auto', width: '100vw', paddingTop: '50vh'}}/>;
    }

    if (isError) {
        return <Result
            status="500"
            title="We seem to be a little too popular right now."
            subTitle="Try refreshing the page. If the error persists, please email support (support@rokfin.com)."
            extra={<Button size='large' onClick={() => window.location.reload(true)} type="primary">Refresh Page</Button>}
        />
    }

    return (
        <div className='app'>
            <Layout>
                <Header className='header'>
                    <AppHeader/>
                </Header>
                <Layout className='layout'>
                    <Routes/>
                </Layout>
            </Layout>
        </div>
    );
}


export default connect(null, {setUser, setPpvData})(App);
